import React from 'react'

import Title from '../../components/sharedComponents/Title/Title'
import Seo from '../../components/Seo'

import '../../assets/css/stylesheet.css'
import * as styles from './styles.module.scss'

const Index = ({ pageContext: { data } }) => {
  console.log('===>data?.newEra', data);
  return (
    <>
      <Seo
        title={'Justice | Cases'}
        description="Justice - We take care of our staff and clients. Let us show who we are."
        htmlAttributes={{
          lang: 'en',
        }}
        url="https://www.it-justice.com/"
      />
        <div className={styles.cases}>
          <div className="container">
            <Title>{data.title}</Title>
          </div>
          <div
            className={styles.casesTop}
            style={{ backgroundColor: `${data.color}` }}
          >
            <div className="container">
              <div className={styles.casesTopImg}>
                <div className={`casesImg ${data.img}`} />
              </div>
            </div>
          </div>
          <div className="container">
            <div className={styles.casesBottom}>
              <div className={styles.casesBottomLeft}>
                <Title>
                  About <br />
                  the <span>project</span>
                </Title>
              </div>
              <div className={styles.casesBottomRight}>
                {data.text.map((text) => (
                  <div>
                    {text}
                    <br />
                    <br />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default Index
